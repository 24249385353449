import { Injectable } from '@angular/core';
import { combineLatest, map, Subject, tap } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { INTEGRATIONS } from 'src/app/modules/integrations/model/integration.const';

// Interfaces
import { HUBSPOT_PROVIDER, MARKETO_PROVIDER, PARDOT_PROVIDER } from 'src/app/services/crm/crm-service.interface';
import {
  IntegrationActiveStatus,
  IntegrationSubtypes,
  IntegrationTypes
} from 'src/app/model/interfaces/integrations.interface';

// Services
import { AlertService } from 'src/app/services/alert/alert.service';
import { AzureSyncService } from 'src/app/services/azure-sync/azure-sync.service';
import { CrmService } from 'src/app/services/crm/crm.service';
import { GoogleSyncService } from 'src/app/services/google-sync/google-sync.service';
import { UtilService } from 'src/app/services/util/util.service';
import { SftpSyncService } from '@services/sftp-sync/sftp-sync.service';

@Injectable()
export class IntegrationsService {
  /**
   * Environment cogsig or mailtastic
   */
  isCogSig = environment.isCogSig;

  integrationSteps = INTEGRATIONS.checkList;

  /**
   * Observable is used to get the Office 365 connection
   */
  getM365Integration$ = this.azureService.getConnectionInfo().pipe(
    map(data => {
      let exist = false;
      let activeStatus = false;
      if (data && data.syncInfoId) {
        exist = true;
        activeStatus = data.connectionError !== true;
      }
      return { exist, activeStatus };
    })
  );

  /**
   * Observable is used to get the gsuit connection
   */
  getGsuiteIntegration$ = this.googleSyncService.get().pipe(map(data => !!(data && data.syncInfoId)));

  /**
   * Observable is used to get the hubspot connection
   */
  getHubSpotIntegration$ = this.crmService.get(HUBSPOT_PROVIDER).pipe(map(data => !!(data && data.syncInfoId)));

  /**
   * Observable is used to get the marketo connection
   */
  geMarketoIntegration$ = this.crmService.get(MARKETO_PROVIDER).pipe(map(data => !!(data && data.syncInfoId)));

  /**
   * Observable is used to get the pardot connection
   */
  getPardotIntegration$ = this.crmService.get(PARDOT_PROVIDER).pipe(map(data => !!(data && data.syncInfoId)));

  /**
   * Observable of get domain count of connection
   *
   * NOTE: Currently we have a single connection only. So, we get data[0] index domain count only
   */
  reroutingDomainCount$ = this.azureService
    .getReroutingConnection()
    .pipe(map(data => !!(data[0] && data[0].domainCount !== 0)));

  /**
   * Observable is used to get the sftp connection
   */
  getSftpIntegration$ = this.sftpSyncService.getConnection().pipe(map(data => !!data));

  /**
   * Observable is used to check integrations are already connected or not in cogsig
   */
  loadCogsigIntegrationData$ = this.getGsuiteIntegration$.pipe(
    map(googleSuit => ({
      googleSuit,
      hubspot: false,
      marketo: false,
      office365: { exist: false, activeStatus: false },
      office365Rerouting: false,
      sftp: false,
      pardot: false
    }))
  );

  /**
   * Observable is used to check integrations are already connected or not
   */
  loadIntegrationData$ = combineLatest([
    this.getM365Integration$,
    this.reroutingDomainCount$,
    this.getGsuiteIntegration$,
    this.getHubSpotIntegration$,
    this.geMarketoIntegration$,
    this.getSftpIntegration$,
    this.getPardotIntegration$
  ]).pipe(
    map(([office365, office365Rerouting, googleSuit, hubspot, marketo, sftp, pardot]) => ({
      googleSuit,
      hubspot,
      marketo,
      office365,
      office365Rerouting,
      sftp,
      pardot
    }))
  );

  /**
   * Holds the integration list data
   */
  private _integrationList$ = new Subject<IntegrationTypes[]>();

  integrationList$ = this._integrationList$.asObservable().pipe(
    tap(data => {
      data.map(integrationData => {
        // Display outlook add in integration when outlook for windows/mac/web is selected
        if (integrationData.id === 1) {
          if (
            integrationData.parts[0].checked ||
            integrationData.parts[2].checked ||
            integrationData.parts[5].checked
          ) {
            integrationData.parts[1].checked = true;
          }
        }

        //   If user selects the `Gmail(Browser)` of Google it will display the integration list `Gmail - via chrome extension` and `Gmail - via manual integration
        if (integrationData.id === 2) {
          if (integrationData.parts[0].checked === true) {
            integrationData.parts[1].checked = true;
          }
        }

        // Display outlook add in integration when outlook for windows/mac/web is selected
        if (integrationData.id === 5) {
          if (integrationData.parts[0].checked === true) {
            integrationData.parts[1].checked = true;
            //TODO: ENABLE WHEN PLAINTEXT AVAILABLE
            // data[4].parts[2].checked = true;
          }
        }
      });

      // // If user selects the `Gmail(Browser)` of Google it will display the integration list `Gmail - via chrome extension` and `Gmail - via manual integration
      // if (data[1].parts[0].checked === true) {
      //   data[1].parts[1].checked = true;
      // }

      // // If user selects the `Other` of other for use signature it will display the integration list of `HTML signature`, `Signature template` and `Plaintext signature(hide temporary)`
      // if (data[4].parts[0].checked === true) {
      //   data[4].parts[1].checked = true;
      //   //TODO: ENABLE WHEN PLAINTEXT AVAILABLE
      //   // data[4].parts[2].checked = true;
      // }

      // // Display outlook add in integration when outlook for windows/mac/web is selected
      // if (data[0].parts[0].checked || data[0].parts[2].checked || data[0].parts[5].checked) {
      //   data[0].parts[1].checked = true;
      // }

      // If user selects the `Other` of other for data imports it will display the integration list of `SFTP` and `API`
      // // Temporary hide SFTP
      // if (data[7].parts[1].checked === true) {
      //   data[7].parts[1].checked = false;
      //   data[7].parts[0].checked = true;
      //   data[8].parts[0].checked = true;
      // }
    })
  );

  private _firstStepShown$ = new Subject<boolean>();

  firstStepShown$ = this._firstStepShown$.asObservable().pipe();

  private _secondStepShown$ = new Subject<boolean>();

  secondStepShown$ = this._secondStepShown$.asObservable();

  private _thirdStepShown$ = new Subject<boolean>();

  thirdStepShown$ = this._thirdStepShown$.asObservable();

  private _isSelectionDisplay$ = new Subject<boolean>();

  isSelectionDisplay$ = this._isSelectionDisplay$.asObservable();

  constructor(
    private alert: AlertService,
    private azureService: AzureSyncService,
    private crmService: CrmService,
    private googleSyncService: GoogleSyncService,
    private router: Router,
    private sftpSyncService: SftpSyncService,
    private utilService: UtilService
  ) {}

  /**
   * Changes from filtered integration checklist
   * @param value - The checkbox value
   */
  updateFilteredIntegrationList(value: IntegrationTypes[]): void {
    this._integrationList$.next(value);
  }

  /**
   * Update steps
   * @param value - The checkbox value
   * @param stepNo - The step number to update
   */
  updateStep(value: boolean, stepNo: number): void {
    switch (stepNo) {
      case 1:
        this._firstStepShown$.next(value);
        break;
      case 2:
        this._secondStepShown$.next(value);
        break;
      case 3:
        this._thirdStepShown$.next(value);
        break;
    }
  }

  /**
   * Redirect to integration
   * @param data - The object of integration subtype
   */
  openIntegration(data: IntegrationSubtypes): void {
    if (data.integrationUrl.startsWith('/')) {
      void this.router.navigateByUrl(data.integrationUrl);
    } else {
      switch (data.id) {
        case 6.1:
          if (this.isCogSig) {
            void this.utilService.redirectToOldAngular('o365');
          } else {
            void this.router.navigate(['/o365/sync']);
          }
          break;
        default:
          data.integrationUrl ? window.open(this.alert.translate(data.integrationUrl)) : '';
          break;
      }
    }
  }

  /**
   * Get the number of integration parts
   * @param data - The object of integration type
   */
  getSectionLength(data: IntegrationTypes): number {
    return data.parts.filter(({ checked }) => checked).length;
  }

  /**
   * Checks the selection list should be shown
   * @param status - The status of display list
   */
  isSelectionDisplay(status: boolean): void {
    this._isSelectionDisplay$.next(status);
  }

  /**
   * Gets active integrations list
   * @param integrationsList - The integration list
   * @param integrationDetails - The connected integration details
   * @returns The activated integration list
   */
  getActiveIntegrations(
    integrationsList: IntegrationTypes[],
    integrationDetails: IntegrationActiveStatus
  ): IntegrationTypes[] {
    let i = 0;
    const list: IntegrationTypes[] = [];
    integrationsList.map(integration => {
      const parts: IntegrationSubtypes[] = [];
      integration.parts.map(part => {
        if (this.checkActiveStatus(integrationDetails, part.integrationUrl)) {
          parts.push(part);
        }
      });
      if (parts.length) {
        list.push(integration);
        list[i].parts = parts;
        i++;
      }
    });
    return list;
  }

  /**
   * Get active status of integration
   * @param integrations - Status object of integration
   * @param integrationUrl - Url of integration
   * @returns Boolean
   */
  checkActiveStatus(integrations: IntegrationActiveStatus, integrationUrl: string): boolean {
    return (
      (integrations.office365 &&
        integrations.office365.exist &&
        integrations.office365.activeStatus &&
        integrationUrl === 'integration_microsoft365_6_1_integration_link') ||
      (integrations.office365Rerouting && integrationUrl === '/rerouting') ||
      (integrations.googleSuit && integrationUrl === '/googlesync') ||
      (integrations.hubspot && integrationUrl === '/hubspot') ||
      (integrations.marketo && integrationUrl === '/marketo') ||
      (integrations.pardot && integrationUrl === '/pardot') ||
      (integrations.sftp && integrationUrl === '/sftp')
    );
  }

  /**
   * Get inactive status of integration
   * @param integrations - Status object of integration
   * @param integrationUrl - Url of integration
   * @returns Boolean
   */
  checkInactiveStatus(integrations: IntegrationActiveStatus, integrationUrl: string): boolean {
    if (
      integrationUrl === 'integration_microsoft365_6_1_integration_link' &&
      integrations.office365.exist &&
      !integrations.office365.activeStatus
    ) {
      return true;
    }
    return false;
  }
}
